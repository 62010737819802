<template>
	<div>
		<a-modal :title="getTitle" :width="480" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定
				</a-button>
			</template>
			<a-spin :spinning="loading">
				<div>
					
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-form-model-item label="分类名称" required >
							<a-input class=""  :suffix="form.name.length + '/5'" :maxLength="5" style="width:200px;"  placeholder="请输入分类名称" v-model="form.name">
							</a-input>
						</a-form-model-item>
						
						<a-form-model-item label="排序" required >
							<a-input-number   v-model="form.sort" :min="0" :precision="0" style="width:100px;"
							 placeholder="请输入排序" />
						</a-form-model-item>
					</a-form-model>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			project_type_id:{
				type:Number,
				default:0
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				labelCol: { span: 6 },
				wrapperCol: { span: 18 },
				form: {
					name:'',
					sort:'',
				}
			}
		},
		computed:{
			getTitle(){
				if(this.project_type_id==0){
					return "添加分类";
				}else{
					return "编辑分类"
				}
			}
		},
		created() {
			this.loaddata();
		},
		methods: {
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showProjectType',{
					project_type_id:this.project_type_id
				}).then(res=>{
					if(res.detail){
						this.form=res.detail;
					}
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);
				})
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveProjectType',{
					project_type_id:this.project_type_id,
					name:this.form.name,
					sort:this.form.sort,
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.$emit('ok');
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
		}
	}
</script>

<style>
	
</style>
