<template>
	<div>
		<div class="pd30">
			<div>
				<a-button type="primary" icon="plus" @click="addTypeAct()">添加分类</a-button>
			</div>
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="分类名称">
						<a-input v-model="search.name" placeholder="请输入分类名称"></a-input>
					</a-form-item>

					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>

			<div class="">
				<div class="wxb-table-gray">
					<a-table rowKey="project_type_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
						:data-source="datas" :loading="loading">
						
						<template slot="action" slot-scope="record,index">
							<div class="flex center">
								<a-dropdown placement="bottomRight">
									<span class="more-act">
										<i class="iconfont iconmore_gray"></i>
									</span>
									<a-menu slot="overlay">
										<a-menu-item>
											<a class="menu-act" href="javascript:;" @click="editTypeAct(record)">
												<i class="iconfont ft14 iconedit"></i>
												<span class="ml10">编辑</span>
											</a>
										</a-menu-item>
										<a-menu-item>
											<a class="menu-act" href="javascript:;" @click="delTypeAct(record)">
												<i class="iconfont ft14 icondelete"></i>
												<span class="ml10">删除</span>
											</a>
										</a-menu-item>
									</a-menu>
								</a-dropdown>
							</div>
						</template>
					</a-table>
				</div>
			</div>
			
			<div v-if="editTypeVisible">
				<edit-type :visible="editTypeVisible" :project_type_id="project_type_id" @cancel="cancelEditType" @ok="okEditType"></edit-type>
			</div>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../../../common/mixin/list.js';
	import editType from './type/modal/edit.vue';
	export default{
		mixins: [listMixin],
		components:{
			editType,
		},
		data(){
			return{
				loading: false,
				editTypeVisible:false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				project_type_id:0,
				search:{
					name:'',
				},
				columns: [
					{title: '分类编号',dataIndex: 'project_type_id',align: 'center'},
					{title: '分类名称',dataIndex: 'name',align: 'center',ellipsis: true},
					{title: '包含项目数',dataIndex: 'project_count',align: 'center',ellipsis: true},
					{title: '排序',dataIndex: 'sort',align: 'center',ellipsis: true},
					{title: '创建时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getProjectTypeList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					name:this.search.name,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			
			delTypeAct(record){
				this.$confirm({
					title:'确认删除这个分类吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delProjectType',{
								project_type_id:record.project_type_id,
							}).then(res=>{
								this.$message.success('删除成功',1,()=>{
									this.getLists();
								});
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			addTypeAct(){
				this.project_type_id=0;
				this.editTypeVisible=true;
			},
			editTypeAct(record){
				this.project_type_id=record.project_type_id;
				this.editTypeVisible=true;
			},
			cancelEditType(){
				this.editTypeVisible=false;
			},
			okEditType(){
				this.editTypeVisible=false;
				this.getLists();
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.projetc-index-cover-img{
		width: 80px;
		height: 60px;
	}
</style>
